<!--<app-header />-->
<router-outlet/>
<dialog #dialog>
  <p class="title" [innerHTML]="alertService.title"></p>
  <p class="message" [innerHTML]="alertService.message"></p>
  <button class="cta mb white small" (click)="dialog.close()">Chiudi</button>
</dialog>

<dialog class="loading" #loading>
  <div class="spinner"></div>
</dialog>
