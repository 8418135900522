import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UserService } from './services/user.service';
import { HeaderComponent } from './components/header/header.component';
import { Title } from '@angular/platform-browser';
import { delay, filter, map } from 'rxjs';
import { environment } from '../environments/environment';
import { VERSION } from '../shared/version';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { AlertService } from './services/alert.service';

register();


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  private router = inject(Router);
  private titleService = inject(Title);
  private document = inject(DOCUMENT);

  alertService = inject(AlertService);

  @ViewChild('dialog', { static: true }) set setDialog(el: ElementRef) {
    this.alertService.initDialog(el.nativeElement);
  }

  @ViewChild('loading', { static: true }) set setLoading(el: ElementRef) {
    this.alertService.initLoading(el.nativeElement);
  }

  constructor(private userService: UserService) {
    this.init();
    this.handleRouteEvents()
  }

  handleRouteEvents() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(0),
        map(event => {
          const { urlAfterRedirects: page_path } = event as NavigationEnd;
          const page_location = this.document.location.href;
          const page_title = this.titleService.getTitle();
          return { page_path, page_location, page_title };
        })
      )
      .subscribe(eventParams => gtag('event', 'page_view', eventParams));
  }

  private init() {
    console.info(
      '%c%s%s%s',
      'font-weight: bold; font-size: 2em',
      '',
      `${VERSION.version} - ${environment.type}`,
      ''
    );

    // Impostazioni di dev/produzione
    if (environment.production) {
      console.log = () => {
      };
    }
  }
}
