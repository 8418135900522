import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../auth.service';

export const authenticationInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {

  const reqWithCustomHeader = req.clone({
    headers: req.headers
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Accept-Language', 'it')
  });

  const authService = inject(AuthService);

  const userToken = authService.getAuthorizationToken();

  if (!userToken) {
    return next(reqWithCustomHeader);
  }

  const modifiedReq = reqWithCustomHeader.clone({
    headers: req.headers.set('Authorization', `Bearer ${userToken}`),
  });

  return next(modifiedReq);
};
